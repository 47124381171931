import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
	BoltIcon,
	ChatBubbleOvalLeftIcon,
	ExclamationCircleIcon,
	HeartIcon,
	MegaphoneIcon,
	NewspaperIcon,
} from "@heroicons/react/24/outline";

// import IconDiscord from "./assets/icon-discord.svg?react";

import PoolsRoute from "internal/routes/pools";
import PoolRoute from "internal/routes/pools/[name]";

import Wallet from "./components/wallet";

const App: React.FunctionComponent<{}> = ({}) => {
	// const intl = useIntl();

	let maintenance:
		| {
				Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
				content: React.ReactNode;
		  }
		| undefined;

	maintenance = {
		Icon: BoltIcon,
		content: "Service is currently in beta",
	};

	return (
		<section className="flex grow flex-col min-h-screen">
			{maintenance && (
				<div className="bg-zinc-900">
					<div className="container">
						<div className="flex items-center justify-center gap-2 p-3 text-xs text-zinc-300">
							<maintenance.Icon className="w-5 flex flex-none" />

							{maintenance.content}
						</div>
					</div>
				</div>
			)}

			<header className="mt-6">
				<div className="container">
					<div className="flex items-center justify-between">
						<nav className="hidden flex items-center gap-8 justify-center">
							{/* <NavLink
								to="/"
								className={({ isActive }) =>
									`mr-1 transition-colors ${
										isActive ? "text-zinc-300" : "text-zinc-600"
									}`
								}
							>
								<HomeIcon className="w-auto h-5" />
							</NavLink> */}

							{(
								[
									{
										to: "/pools",
										label: <FormattedMessage defaultMessage="Pools" />,
										Icon: BoltIcon,
									},
								] as Array<{
									to: string;
									label?: React.ReactNode;
									Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
								}>
							).map(({ to, label, Icon }) => (
								<NavLink
									key={to}
									to={to}
									className={({ isActive }) =>
										[
											"flex items-center gap-2 text-base transition-colors",
											isActive ? "text-zinc-200 font-medium" : "text-zinc-400",
										]
											.filter(Boolean)
											.join(" ")
									}
								>
									{({ isActive }) => (
										<>
											{isActive && Icon && <Icon className="w-5 h-auto" />}

											{label}
										</>
									)}
								</NavLink>
							))}
						</nav>

						<div />

						<div className="flex items-center gap-6 basis-48 justify-end">
							<Wallet />
						</div>
					</div>
				</div>
			</header>

			<main className="flex grow mt-6">
				<div className="container">
					<Routes>
						{/* <Route path="/" Component={HomeRoute} /> */}
						<Route path="/pools/:jetton/:os" Component={PoolRoute} />
						<Route path="/pools" Component={PoolsRoute} />
						<Route index element={<Navigate to="/pools" />} />
					</Routes>
				</div>
			</main>

			<footer className="mt-6">
				<div className="container">
					<div className="flex max-md:flex-col max-md:gap-2 md:items-center justify-between border-t border-zinc-900 py-6">
						<nav className="flex items-center gap-6">
							{[
								{
									href: "https://t.me/ton_gives",
									label: <FormattedMessage defaultMessage="News" />,
									Icon: MegaphoneIcon,
								},
								{
									href: "https://t.me/ton_gives_chat",
									label: <FormattedMessage defaultMessage="Chat" />,
									Icon: ChatBubbleOvalLeftIcon,
								},
							].map(({ href, label, Icon }, index) => (
								<a
									key={index}
									href={href}
									target="_blank"
									rel="noopener"
									className="flex items-center gap-1.5 text-base text-zinc-500 hover:text-zinc-300 transition-colors"
								>
									<Icon className="w-auto h-5" />

									{label}
								</a>
							))}
						</nav>

						<div className="flex items-center gap-1 text-zinc-500">
							With
							<HeartIcon className="w-4 h-4" />
							by{" "}
							<a
								href="https://ton.careers"
								target="_blank"
								className="font-medium transition-colors text-zinc-400 hover:text-zinc-300"
							>
								ton.careers
							</a>
						</div>

						{/* <div className="flex items-center gap-6">
							<nav className="flex items-center gap-6">
								{[
									{
										Icon: IconDiscord,
										href: "https://discord.com",
										className: "w-auto h-[0.85rem]",
									},
								].map(({ Icon, href, className }, index) => (
									<a
										key={index}
										href={href}
										target="_blank"
										rel="noopener noreferrer"
										className="text-zinc-500 text-base"
									>
										<Icon
											className={`${className} w-auto text-zinc-700 hover:text-zinc-600 transition-colors`}
										/>
									</a>
								))}
							</nav>
						</div> */}
					</div>
				</div>
			</footer>
		</section>
	);
};

export default App;
