import { useSession } from "internal/store/session";

import WalletUnauthorized from "./unauthorized";
import WalletPlaceholder from "./placeholder";
import WalletAuthorized from "./authorized";

const Wallet: React.FunctionComponent<{}> = ({}) => {
	const session = useSession();

	if (session === undefined) {
		return <WalletPlaceholder />;
	}

	return session ? (
		<WalletAuthorized session={session} />
	) : (
		<WalletUnauthorized />
	);
};

export default Wallet;
