import { WalletIcon } from "@heroicons/react/24/outline";

import IconSpin from "internal/assets/icon-spin.svg?react";
import Button from "internal/interface/button";

const WalletPlaceholder: React.FunctionComponent<{}> = ({}) => {
	return (
		<Button disabled Icon={WalletIcon}>
			<IconSpin className="w-6 h-auto" />
		</Button>
	);
};

export default WalletPlaceholder;
