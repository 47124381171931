import {
	atom,
	useRecoilState,
	useRecoilValue,
	useSetRecoilState,
} from "recoil";
import {
	type TonProofItemReply,
	type TonProofItemReplySuccess,
} from "@tonconnect/ui";

import tonconnect from "internal/tonconnect";

export type Session = {
	id: number;
	address: string;
};

const value = atom<Session | null | undefined>({
	key: "session",
	default: undefined,
	effects: [
		({ trigger, setSelf }) => {
			/**
			 * Restore session from the server
			 * And check if the session is still valid
			 */
			async function restoreSession(): Promise<void> {
				await tonconnect.connectionRestored;

				const response = await fetch(
					import.meta.env.VITE_API_BASE + "/session",
					{
						credentials: "include",
					},
				);

				if (response.status === 401) {
					if (tonconnect.wallet) {
						await tonconnect.disconnect();
					}

					return setSelf(null);
				}

				if (!tonconnect.wallet) {
					const response = await fetch(
						import.meta.env.VITE_API_BASE + "/session/disconnect",
						{
							credentials: "include",
						},
					);

					return setSelf(null);
				}

				setSelf(await response.json());
			}

			if (trigger === "get") {
				restoreSession();
			}

			return tonconnect.onStatusChange((wallet) => {
				setSelf((previousSession) => {
					if (!wallet && previousSession) {
						return null;
					}

					return previousSession;
				});
			});
		},
	],
});

export function useSession(): Session | undefined | null {
	return useRecoilValue(value);
}

export function useSessionState() {
	return useRecoilState(value);
}

export function useSetSession() {
	return useSetRecoilState(value);
}

function isTonProofSuccess(
	value: TonProofItemReply,
): value is TonProofItemReplySuccess {
	return "proof" in value;
}

export function useConnect() {
	const [session, setSession] = useRecoilState(value);

	async function connect(): Promise<void> {
		if (session) {
			throw new Error("Already connected");
		}

		tonconnect.setConnectRequestParameters({
			state: "loading",
		});

		tonconnect.modal.open();

		const response = await fetch(import.meta.env.VITE_API_BASE + "/connect", {
			credentials: "include",
		});

		tonconnect.setConnectRequestParameters({
			state: "ready",
			value: {
				tonProof: await response.text(),
			},
		});

		const unlisten = tonconnect.onStatusChange((wallet) => {
			if (
				wallet &&
				wallet.connectItems &&
				wallet.connectItems.tonProof &&
				isTonProofSuccess(wallet.connectItems.tonProof)
			) {
				fetch(import.meta.env.VITE_API_BASE + "/connect", {
					method: "POST",
					credentials: "include",
					body: JSON.stringify({
						address: wallet.account.address,
						publicKey: wallet.account.publicKey,
						stateInit: wallet.account.walletStateInit,
						payload: wallet.connectItems.tonProof.proof.payload,
						timestamp: wallet.connectItems.tonProof.proof.timestamp,
						signature: wallet.connectItems.tonProof.proof.signature,
						domain: {
							value: wallet.connectItems.tonProof.proof.domain.value,
							length: wallet.connectItems.tonProof.proof.domain.lengthBytes,
						},
					}),
				})
					.then(async (response) => {
						if (response.status !== 200) {
							return tonconnect.disconnect();
						}

						setSession(await response.json());
					})
					.catch(tonconnect.disconnect.bind(tonconnect))
					.finally(unlisten);
			}
		});
	}

	return connect;
}

export default value;
