import { StrictMode } from "react";
import { RecoilRoot } from "recoil";
import { IntlProvider } from "react-intl";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from "./app";
import "./index.css";

Sentry.init({
	dsn: "https://5857a3ff7d97fe3b26f4980523b31636@o4507435480580096.ingest.de.sentry.io/4507435686953040",
	integrations: [
		// Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	tracePropagationTargets: ["localhost", /^https:\/\/(.*\.)?ton\.gives/],
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
if (!container) {
	throw new Error("No container found");
}

createRoot(container).render(
	<StrictMode>
		<RecoilRoot>
			<IntlProvider locale="en">
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</IntlProvider>
		</RecoilRoot>
	</StrictMode>,
);
