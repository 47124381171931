import { THEME, TonConnectUI } from "@tonconnect/ui";

const tonconnect = new TonConnectUI({
	manifestUrl: "https://ton.gives/connect-manifest.json",
	uiPreferences: {
		theme: THEME.DARK,
		// colorsSet: {
		// 	[THEME.DARK]: {
		// 		background: {
		// 			primary: "#18181B",
		// 		},
		// 	},
		// },
	},
});

export default tonconnect;
