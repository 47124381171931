import { forwardRef } from "react";

const Button = forwardRef<
	HTMLButtonElement,
	{
		active?: boolean;
		Icon?: React.FunctionComponent<React.ComponentProps<"svg">>;
	} & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ active, Icon, className, children, ...props }, ref) => {
	return (
		<button
			ref={ref}
			className={[
				"flex items-center rounded cursor-pointer transition-all gap-2 px-5 py-2.5",
				active ? "bg-zinc-800" : "bg-zinc-900 hover:bg-zinc-800",
				className,
			]
				.filter(Boolean)
				.join(" ")}
			{...props}
		>
			{Icon && <Icon className="w-5 h-auto text-zinc-500" />}

			{children}
		</button>
	);
});

export default Button;
