import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	ArrowLongRightIcon,
	ArrowTrendingDownIcon,
	ArrowTrendingUpIcon,
	BanknotesIcon,
	CurrencyDollarIcon,
	TicketIcon,
} from "@heroicons/react/24/outline";
import { FormattedMessage, FormattedNumber } from "react-intl";

const PoolsRoute: React.FunctionComponent<{}> = ({}) => {
	const [data, setData] = useState<
		| Array<{
				id: number;
				fee: number;
				name: string;
				slug: string;
				symbol: string;
				address: string;
				threshold: number;
				rateTon: number;
				rateUsd: number;
				usdDiff24h: number;
				tonDiff24h: number;

				givers: Array<{
					address: string;
				}>;

				descriptions: Array<{
					language: string;
					value: string;
				}>;

				stats: Array<{
					date: string;
					shares: number;
					transactions: number;
				}>;
		  }>
		| Error
		| undefined
	>();

	useEffect(() => {
		fetch(`${import.meta.env.VITE_API_BASE}/pools`, {
			credentials: "include",
		})
			.then((response) => response.json())
			.then(setData)
			.catch(setData);
	}, []);

	return (
		<div className="flex flex-col divide-y divide-zinc-900">
			{data === undefined
				? Array.from({
						length: 2,
				  }).map((_, index) => (
						<div key={index} className="flex items-center justify-between py-4">
							<div className="flex gap-4 items-center">
								{/* <div className="w-12 h-12 bg-zinc-900 rounded-full" /> */}

								<div className="flex flex-col gap-1">
									<div className="w-32 h-4 my-1 bg-zinc-900/60 rounded animate-pulse" />
									<div className="w-64 h-3 my-1 bg-zinc-900/60 rounded animate-pulse" />
									<div className="w-64 md:w-[32rem] h-3 my-1 bg-zinc-900/60 rounded animate-pulse" />
									<div className="md:hidden w-24 h-3 my-1 bg-zinc-900/60 rounded animate-pulse" />
									<div className="md:hidden w-64 h-3 my-1 bg-zinc-900/60 rounded animate-pulse" />
								</div>
							</div>

							<ArrowLongRightIcon className="flex-none w-6 h-6 transition-colors text-zinc-700 group-hover:text-zinc-400" />
						</div>
				  ))
				: data instanceof Error
				? "Error"
				: data.map(
						({
							id,
							fee,
							name,
							slug,
							symbol,
							threshold,
							descriptions,
							rateUsd,
							rateTon,
							usdDiff24h,
							tonDiff24h,
						}) => {
							const description =
								descriptions.find(({ language }) => language === "en") ??
								descriptions[0];

							if (!description) {
								throw new Error("Pool must have at least one description");
							}

							const TrendingIcon =
								usdDiff24h > 0 ? ArrowTrendingUpIcon : ArrowTrendingDownIcon;

							return (
								<Link
									key={id}
									to={`/pools/${slug}/linux`}
									className="group flex items-center justify-between py-4"
								>
									<div className="flex gap-4 items-center">
										{/* <div className="w-12 h-12 bg-zinc-900 rounded-full" /> */}

										<div className="flex flex-col gap-1">
											<span className="text-base font-medium">{name}</span>

											<span className="text-sm text-zinc-500">
												{description.value}
											</span>

											<div className="flex max-md:flex-col max-md:gap-1 md:items-center md:gap-2 text-sm">
												{[
													<span className="flex items-center gap-0.5 text-zinc-400">
														1 {symbol} ={" "}
														<CurrencyDollarIcon className="w-4 h-4 text-white ml-0.5" />
														<span className="text-white font-medium">
															<FormattedNumber
																value={rateUsd}
																minimumFractionDigits={4}
																maximumFractionDigits={4}
															/>
														</span>
														{/* {usdDiff24h > 0 ? "+" : "–"} */}
														<TrendingIcon className="w-4 h-4 ml-1 mr-0.5" />
														<FormattedNumber
															value={Math.abs(usdDiff24h) / 100}
															style="percent"
															minimumFractionDigits={2}
														/>
													</span>,

													<span className="flex items-center gap-0.5 text-zinc-400">
														<TicketIcon className="w-4 h-4 mr-1" />

														<FormattedMessage
															defaultMessage="Fee {value}"
															values={{
																value: (
																	<b className="text-white font-medium">
																		{fee}%
																	</b>
																),
															}}
														/>
													</span>,

													<span className="flex items-center gap-0.5 text-zinc-400">
														<BanknotesIcon className="w-4 h-4 mr-1" />

														<FormattedMessage defaultMessage="Automatic withdrawals" />
													</span>,
												].map((element, index, array) => (
													<Fragment key={index}>
														{element}

														{index !== array.length - 1 && (
															<div className="max-md:hidden w-1 h-1 bg-zinc-700 rounded-full" />
														)}
													</Fragment>
												))}
											</div>
										</div>
									</div>

									{/* <div className="flex gap-4 items-center">
										<div className="w-20 h-4 bg-zinc-900 rounded" />
										<div className="w-16 h-4 bg-zinc-900 rounded" />
									</div> */}

									<ArrowLongRightIcon className="flex-none w-6 h-6 transition-colors text-zinc-700 group-hover:text-zinc-400" />
								</Link>
							);
						},
				  )}
		</div>
	);
};

export default PoolsRoute;
