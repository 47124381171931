import { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
	offset,
	safePolygon,
	useFloating,
	useHover,
	useInteractions,
} from "@floating-ui/react";
import {
	WalletIcon,
	ChevronDownIcon,
	ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import { animated, useTransition } from "@react-spring/web";

import { type Session } from "internal/store/session";
import Button from "internal/interface/button";
import tonconnect from "internal/tonconnect";

const WalletAuthorized: React.FunctionComponent<{
	session: Session;
}> = ({ session }) => {
	const [open, onOpenChange] = useState(false);

	const floating = useFloating({
		open,
		onOpenChange,
		middleware: [offset(10)],
	});

	const hover = useHover(floating.context, {
		handleClose: safePolygon({
			blockPointerEvents: false,
		}),
	});

	const interactions = useInteractions([hover]);

	const transitions = useTransition(open, {
		from: { opacity: 0, y: -5 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, y: -5 },
	});

	async function disconnect(): Promise<void> {
		await tonconnect.disconnect();

		try {
			await fetch(import.meta.env.VITE_API_BASE + "/session/disconnect", {
				credentials: "include",
			});
		} catch (error) {
			console.error(error);
		}
	}

	return (
		<div className="relative">
			<Button
				active={open}
				ref={floating.refs.setReference}
				Icon={WalletIcon}
				{...interactions.getReferenceProps()}
			>
				{session.address.slice(0, 4) + "..." + session.address.slice(-4)}

				<ChevronDownIcon className="w-5 h-auto text-zinc-500" />
			</Button>

			{transitions(
				(style, item) =>
					item && (
						<animated.div
							ref={floating.refs.setFloating}
							style={{ ...floating.floatingStyles, ...style }}
							className="bg-zinc-900 rounded z-10"
							{...interactions.getFloatingProps()}
						>
							<button
								className="flex items-center justify-center transition-colors rounded gap-2 px-5 py-2.5 hover:bg-zinc-800"
								onClick={disconnect}
							>
								<ArrowUturnLeftIcon className="w-auto h-5 flex-none text-zinc-500" />

								<FormattedMessage defaultMessage="Disconnect" />
							</button>
						</animated.div>
					),
			)}
		</div>
	);
};

export default WalletAuthorized;
