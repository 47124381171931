import { FormattedMessage } from "react-intl";
import { WalletIcon } from "@heroicons/react/24/outline";

import { useConnect } from "internal/store/session";
import Button from "internal/interface/button";

const WalletUnauthorized: React.FunctionComponent<{}> = ({}) => {
	const connect = useConnect();

	return (
		<Button Icon={WalletIcon} onClick={connect}>
			<FormattedMessage defaultMessage="Connect Wallet" />
		</Button>
	);
};

export default WalletUnauthorized;
