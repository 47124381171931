import { Link } from "react-router-dom";

const Navigation: React.FunctionComponent<
	{
		value: string;
		sections: Array<{
			value: string;
			label: React.ReactNode;
		}>;
	} & React.HTMLAttributes<HTMLDivElement>
> = ({ value: currentValue, sections, className, ...props }) => {
	return (
		<div className={`flex flex-col gap-1 ${className}`} {...props}>
			{sections.map(({ value, label }) => (
				<Link
					to={value}
					key={value}
					className={[
						"group flex items-center justify-between gap-2 py-2.5 transition-colors border-zinc-900 border-b last:border-b-0",
						currentValue === value
							? "text-zinc-100 font-medium"
							: "text-zinc-400 hover:text-zinc-100",
					].join(" ")}
				>
					<span className="text-base">{label}</span>

					{/* <FolderOpenIcon
						className={`w-5 h-5 transition-opacity text-zinc-600 ${
							currentValue === value ? "opacity-100" : "opacity-0"
						}`}
					/> */}
				</Link>
			))}
		</div>
	);
};

export default Navigation;
