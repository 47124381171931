import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
	CheckIcon,
	CodeBracketIcon,
	Cog8ToothIcon,
	CubeIcon,
	DocumentDuplicateIcon,
	DocumentTextIcon,
	WalletIcon,
} from "@heroicons/react/24/outline";
import { useFloating } from "@floating-ui/react";
import { animated, useSpring } from "@react-spring/web";

import IconSpin from "internal/assets/icon-spin.svg?react";

const Code: React.FunctionComponent<{
	text: string;
	loading?: boolean;
}> = ({ text, loading }) => {
	const floating = useFloating({
		placement: "top-end",
	});

	const [spring, springRef] = useSpring(() => {
		return {
			config: { tension: 300 },
			opacity: 0,
			y: 0,
		};
	});

	useEffect(() => {
		floating.update();
	}, [text]);

	function onClick(): void {
		navigator.clipboard.writeText(text);

		springRef.start({
			from: {
				opacity: 0,
				y: 0,
			},

			to: async (next) => {
				await next({ opacity: 1, y: -5 });
				await new Promise((resolve) => setTimeout(resolve, 1500));
				await next({ opacity: 0 });
			},
		});
	}

	return (
		<div className="relative">
			<button
				ref={floating.refs.setReference}
				className="group flex w-fit max-w-full bg-zinc-950 rounded px-2 py-1.5 gap-2"
				onClick={onClick}
				disabled={loading}
			>
				{loading ? (
					<IconSpin className="flex-none w-5 h-auto" />
				) : (
					<>
						<code className="block min-w-0 break-words text-left">{text}</code>

						<DocumentDuplicateIcon className="flex-none w-4 pt-0.5 h-auto transition-colors text-zinc-500 group-hover:text-zinc-400" />
					</>
				)}
			</button>

			<animated.div
				ref={floating.refs.setFloating}
				style={{ ...floating.floatingStyles, ...spring }}
				className="flex items-center bg-zinc-950 px-2 py-1 rounded pointer-events-none text-sm"
			>
				<CheckIcon className="flex-none w-4 h-auto -ml-1 mr-1 text-zinc-500" />
				<FormattedMessage defaultMessage="Copied" />
			</animated.div>
		</div>
	);
};

type Step = {
	label: React.ReactNode;
	nodes: React.ReactNode[];
	Icon: React.FunctionComponent<React.ComponentProps<"svg">>;
};

export default function describeSteps(
	os: "windows" | "linux" | "hiveon",
	wallet: string,
	data?: {
		id: number;
		slug: string;
		endpoint: string;
	},
): Step[] {
	if (os === "windows") {
		return [
			{
				label: <FormattedMessage defaultMessage="Install" />,
				nodes: [
					<FormattedMessage
						key={0}
						tagName="span"
						defaultMessage="Download <a>installation script</a> and run it."
						values={{
							a(text) {
								return data ? (
									<a
										href={`https://get.ton.gives/${data.id}/${wallet}.bat`}
										className="underline"
										rel="noopener noreferrer"
										target="_blank"
									>
										{text}
									</a>
								) : (
									<IconSpin className="flex-none w-4 h-auto inline" />
								);
							},
						}}
					/>,
				],
				Icon: CubeIcon,
			},

			{
				label: <FormattedMessage defaultMessage="Start" />,
				nodes: [
					<FormattedMessage
						key={0}
						tagName="span"
						defaultMessage="After the installation script finishes, a script named {name} will appear on your desktop. You should use this script to start the mining software by double-clicking it. In certain situations, you might need to run it as an administrator."
						values={{
							name: data ? (
								<span className="underline">mine_{data.slug}.bat</span>
							) : (
								<IconSpin className="flex-none w-4 h-auto inline" />
							),
						}}
					/>,
				],
				Icon: CodeBracketIcon,
			},
		];
	}

	if (os === "linux") {
		return [
			{
				label: <FormattedMessage defaultMessage="Install" />,
				nodes: [
					<FormattedMessage
						key={0}
						tagName="span"
						defaultMessage="To install, run the following command in your terminal:"
					/>,

					<Code
						key={1}
						loading={!data}
						text={`curl https://get.ton.gives/${
							data ? data.id : 0
						}/${wallet} | bash`}
					/>,
				],
				Icon: CubeIcon,
			},

			{
				label: <FormattedMessage defaultMessage="Start" />,
				nodes: [
					<FormattedMessage
						key={0}
						tagName="span"
						defaultMessage="After the installation script finishes, a script named {name} will appear in your home directory. To launch the mining software, execute this script. In certain situations, you might need to run it with sudo privileges."
						values={{
							name: data ? (
								<span className="underline">mine_{data.slug}.sh</span>
							) : (
								<IconSpin className="flex-none w-4 h-auto inline" />
							),
						}}
					/>,
				],
				Icon: CodeBracketIcon,
			},
		];
	}

	return [
		{
			label: <FormattedMessage defaultMessage="Add wallet" />,
			nodes: [
				<FormattedMessage
					key={0}
					tagName="span"
					defaultMessage="It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged"
				/>,
			],
			Icon: WalletIcon,
		},
		{
			label: <FormattedMessage defaultMessage="Add flight sheet" />,
			nodes: [
				<FormattedMessage
					key={0}
					tagName="span"
					defaultMessage="It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages"
				/>,
			],
			Icon: DocumentTextIcon,
		},
		{
			label: <FormattedMessage defaultMessage="Setup miner config" />,
			nodes: [
				<FormattedMessage
					key={0}
					tagName="span"
					defaultMessage="Contrary to popular belief, Lorem Ipsum is not simply random text"
				/>,
				<Code key={1} loading={!data} text={data ? data.endpoint : ""} />,
			],
			Icon: Cog8ToothIcon,
		},
	];
}
