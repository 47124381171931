import { Fragment, useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import {
	FormattedMessage,
	FormattedNumber,
	FormattedRelativeTime,
} from "react-intl";
import {
	ArrowUturnLeftIcon,
	BanknotesIcon,
	ExclamationCircleIcon,
	HandRaisedIcon,
	TicketIcon,
} from "@heroicons/react/24/outline";

import { useConnect, useSession } from "internal/store/session";
import IconSpin from "internal/assets/icon-spin.svg?react";
import Navigation from "internal/interface/navigation";
// import Button from "internal/interface/button";
import Tooltip from "internal/components/tooltip";

import describeSteps from "./describe-steps";
import PoolChart from "./chart";

const PoolRoute: React.FunctionComponent<{}> = ({}) => {
	const session = useSession();
	const connect = useConnect();

	const params = useParams<{
		jetton: string;
		os: string;
	}>();

	if (!params.jetton || !params.os) {
		throw new Error("Missing parameters");
	}

	const [data, setData] = useState<
		| {
				id: number;
				fee: number;
				name: string;
				slug: string;
				symbol: string;
				address: string;
				endpoint: string;
				threshold: number;

				givers: Array<{
					address: string;
				}>;

				descriptions: Array<{
					language: string;
					value: string;
				}>;

				stats: Array<{
					date: string;
					shares: number;
					hashes: number;
					transactions: number;
				}>;
		  }
		| Error
		| null
		| undefined
	>();

	const [txdata, setTxData] = useState<
		| Array<{
				date: string;
				hash: string;
				status: number;
		  }>
		| Error
		| undefined
	>();

	const [balanceData, setBalanceData] = useState<
		| {
				value: string;
		  }
		| Error
		| undefined
	>();

	const destination = `${import.meta.env.VITE_API_BASE}/pools/` + params.jetton;

	useEffect(() => {
		fetch(destination)
			.then((response) => (response.status === 404 ? null : response.json()))
			.then(setData)
			.catch(setData);
	}, []);

	useEffect(() => {
		if (data) {
			fetch(destination + "/transactions")
				.then((response) => response.json())
				.then(setTxData)
				.catch(setTxData);
		}
	}, [data]);

	useEffect(() => {
		if (data && !(data instanceof Error) && session) {
			fetch(import.meta.env.VITE_API_BASE + "/session/balance/" + data.id, {
				credentials: "include",
			})
				.then((response) => response.json())
				.then(setBalanceData)
				.catch(setBalanceData);
		}
	}, [data, session]);

	if (data === null) {
		return <Navigate to="/pools" />;
	} else if (
		params.os !== "windows" &&
		params.os !== "linux" &&
		params.os !== "hiveon"
	) {
		return <Navigate to={`/pools/${params.jetton}/linux`} />;
	}

	return (
		<div className="flex flex-col gap-4 grow h-full">
			<Link
				to="/pools"
				className="flex items-center gap-2 text-zinc-400 hover:text-zinc-300 transition-colors"
			>
				<ArrowUturnLeftIcon className="w-5 h-auto" />

				<span className="text-sm tracking-widest">
					<FormattedMessage defaultMessage="To pools" />
				</span>
			</Link>

			<div className="flex max-lg:flex-col lg:items-center lg:justify-between max-lg:gap-4 lg:gap-12">
				{data && !(data instanceof Error) ? (
					<div>
						<span className="text-2xl font-semibold tracking-wide">
							{data.name}
						</span>

						<span className="block mt-0.5 text-sm text-zinc-500">
							{
								(
									data.descriptions.find(({ language }) => language === "en") ??
									data.descriptions[0]
								)?.value
							}
						</span>

						<div className="relative flex items-center gap-2 mt-1.5">
							{[
								<Tooltip
									key={0}
									content={
										<span>
											<FormattedMessage defaultMessage="Pool takes a fee from the rewards to cover infrastructure costs and development" />
										</span>
									}
								>
									{(bindings) => (
										<div
											{...bindings}
											className="flex items-center gap-1.5 max-w-80 text-sm rounded cursor-help"
										>
											<TicketIcon className="w-5 h-auto text-zinc-400" />

											<span className="text-zinc-400">
												<FormattedMessage
													defaultMessage="Fee {value}"
													values={{
														value: (
															<b className="text-zinc-100 font-medium">
																{data.fee}%
															</b>
														),
													}}
												/>
											</span>
										</div>
									)}
								</Tooltip>,

								session && (
									<Tooltip
										key={1}
										content={
											<span>
												<FormattedMessage
													defaultMessage="Withdrawals are made automatically once the minimum balance threshold of {value} coins is reached"
													values={{
														value: (
															<b className="text-white font-medium">
																{data.threshold / 1_000_000_000}
															</b>
														),
													}}
												/>
											</span>
										}
									>
										{(bindings) => (
											<div
												{...bindings}
												className="flex items-center gap-1.5 max-w-80 text-sm rounded cursor-help"
											>
												<BanknotesIcon className="w-5 h-auto text-zinc-400" />

												<span className="text-zinc-400">
													<FormattedMessage
														defaultMessage="Balance {value}"
														values={{
															value:
																balanceData &&
																!(balanceData instanceof Error) ? (
																	<b className="text-white font-medium">
																		<FormattedNumber
																			value={
																				parseInt(balanceData.value) /
																				1_000_000_000
																			}
																			minimumFractionDigits={4}
																		/>
																	</b>
																) : (
																	<IconSpin className="flex-none text-white w-4 h-auto inline" />
																),
														}}
													/>
												</span>
											</div>
										)}
									</Tooltip>
								),
							]
								.filter(Boolean)
								.map((element, index, array) => (
									<Fragment key={index}>
										{element}

										{index !== array.length - 1 && (
											<div className="max-md:hidden w-1 h-1 bg-zinc-700 rounded-full" />
										)}
									</Fragment>
								))}

							{/* {[
								{
									href: `https://tonscan.org/account/${data.address}`,
									label: "Tonscan",
									Icon: ShieldExclamationIcon,
								},
							].map(({ href, label, Icon }) => (
								<a
									key={href}
									href={href}
									target="_blank"
									rel="noopener"
									className="group rounded bg-zinc-900 px-3 py-1.5 text-sm"
								>
									<Icon className="inline w-4 h-auto text-zinc-300 -mt-0.5" />

									<span className="ml-1 transition-colors text-zinc-400 group-hover:text-zinc-100">
										{label}
									</span>
								</a>
							))} */}
						</div>
					</div>
				) : (
					<div>
						<div className="h-6 w-28 my-1 bg-zinc-900/60 rounded animate-pulse" />

						<div className="h-4 w-64 mt-2.5 bg-zinc-900/60 rounded animate-pulse" />

						<div className="w-64 h-5 mt-1.5 bg-zinc-900/60 text-sm rounded animate-pulse" />
					</div>
				)}

				<div className="lg:-my-4 lg:w-[60%]">
					<PoolChart
						data={data && !(data instanceof Error) ? data.stats : []}
					/>
				</div>
			</div>

			<div className="flex-none flex flex-col gap-3 pt-4 border-t border-zinc-900">
				<span className="text-sm text-zinc-400 tracking-widest">
					<FormattedMessage defaultMessage="Recent transactions" />
				</span>

				<div className="relative grid grid-rows-1 grid-flow-col gap-x-6 gap-y-2 overflow-hidden">
					{!txdata || txdata instanceof Error || txdata.length === 0
						? Array.from({ length: 10 }).map((_, index) => (
								<div key={index} className="flex flex-col">
									<div className="w-32 h-4 bg-zinc-900/60 rounded animate-pulse" />

									<div className="w-16 h-3.5 mt-1.5 bg-zinc-900/60 rounded animate-pulse" />
								</div>
						  ))
						: txdata.map((tx) => {
								const delta = Math.round(
									(Date.parse(tx.date) - Date.now()) / 1_000,
								);

								// const hexEncodedHash = [...atob(tx.hash)]
								// 	.map((char) =>
								// 		char.charCodeAt(0).toString(16).padStart(2, "0"),
								// 	)
								// 	.join("");

								return (
									<a
										key={tx.hash}
										className="flex flex-col"
										href={`https://tonscan.org/tx/${tx.hash}`}
										target="_blank"
									>
										<span className="text-zinc-300 text-sm">
											{tx.hash.slice(0, 16)}
										</span>

										<div className="flex items-center gap-1">
											<span className="text-zinc-400 text-xs">
												<FormattedRelativeTime
													style="long"
													unit="second"
													value={delta}
													updateIntervalInSeconds={30}
												/>
											</span>

											{tx.status !== 0 && (
												<div className="w-1.5 h-1.5 bg-yellow-600 rounded-full" />
											)}
										</div>
									</a>
								);
						  })}

					<div className="absolute top-0 right-0 w-24 h-full bg-gradient-to-r to-zinc-950 from-zinc-950/0 pointer-events-none" />
				</div>
			</div>

			<div className="flex max-lg:flex-col pt-4 border-t border-zinc-900 gap-4 lg:gap-16">
				<div className="flex-none flex flex-col gap-3 lg:basis-52">
					<span className="text-sm text-zinc-400 tracking-widest">
						<FormattedMessage defaultMessage="Operating System" />
					</span>

					<Navigation
						value={location.pathname}
						sections={[
							{
								value: `/pools/${params.jetton}/linux`,
								label: "Linux",
							},
							{
								value: `/pools/${params.jetton}/hiveon`,
								label: "Hiveon",
							},
							{
								value: `/pools/${params.jetton}/windows`,
								label: "Windows",
							},
						]}
					/>
				</div>

				<div className="flex flex-col gap-3 grow">
					<span className="text-sm text-zinc-400 tracking-widest capitalize">
						<FormattedMessage
							defaultMessage="{os} Setup"
							values={{
								os: params.os,
							}}
						/>
					</span>

					{session !== undefined ? (
						session === null ? (
							<button
								className="flex items-center rounded gap-2 p-2.5 transition-colors bg-zinc-900 hover:bg-zinc-800"
								onClick={connect}
							>
								<ExclamationCircleIcon className="w-5 h-auto text-zinc-400" />

								<span className="text-base">
									<FormattedMessage defaultMessage="Connect your wallet to start" />
								</span>
							</button>
						) : (
							<div key={params.os} className="flex flex-col gap-3 text-base">
								{describeSteps(
									params.os,
									session.address,
									data instanceof Error ? undefined : data,
								).map(({ label, nodes, Icon }, index) => (
									<div key={index} className="rounded p-2.5 bg-zinc-900">
										<div className="flex items-center gap-2 mb-2">
											<Icon className="w-5 h-auto text-zinc-400" />

											<span className="text-base font-medium">{label}</span>
										</div>

										<div className="flex flex-col gap-2 text-sm text-zinc-300">
											{nodes}
										</div>
									</div>
								))}

								{/* {params.os !== "hiveon" && (
									<div className="rounded p-2.5 border border-zinc-900">
										<div className="flex items-center gap-2 mb-2">
											<HandRaisedIcon className="w-5 h-auto text-zinc-400" />

											<span className="text-base font-medium text-zinc-100">
												<FormattedMessage defaultMessage="Note" />
											</span>
										</div>

										<span className="text-sm text-zinc-300">
											<FormattedMessage defaultMessage="Please note that there is no need to provide additional personal information, such as your wallet address, as it is already included in the installation script." />
										</span>
									</div>
								)} */}
							</div>
						)
					) : (
						<div className="w-full h-[2.75rem] bg-zinc-900/60 rounded animate-pulse" />
					)}
				</div>
			</div>
		</div>
	);
};

export default PoolRoute;
