import React from "react";
import { useIntl } from "react-intl";
import {
	XAxis,
	Tooltip,
	ResponsiveContainer,
	Area,
	AreaChart,
	TooltipProps,
} from "recharts";

// const offset = 10;

// const start = new Date();

// start.setHours(start.getHours() - 1);
// start.setMinutes(0);
// start.setSeconds(0);
// start.setMilliseconds(0);

// const data = Array.from({
// 	length: offset,
// })
// 	.map((_, index) => {
// 		return {
// 			date: new Date(start.getTime() - index * 3_600_000),
// 			a: 100 - Math.random() * 50,
// 			b: 100 - Math.random() * 10,
// 		};
// 	})
// 	.reverse();

/**
 *
 * @param exponentValue
 * @param precision
 */
function printHashrate(exponentValue: number, precision = 2): string {
	// Define the units for display
	const units = [
		"y",
		"z",
		"a",
		"f",
		"p",
		"n",
		"u",
		"m",
		"",
		"k",
		"M",
		"G",
		"T",
		"P",
		"E",
		"Z",
		"Y",
	] as const;

	const exponentParts = Number(exponentValue).toExponential(2).split("e");

	// Calculate the index for the unit
	let unitIndex = Math.floor(Number(exponentParts[1]) / 3) + 8;
	unitIndex = Math.max(0, Math.min(unitIndex, units.length - 1));

	if (!exponentParts[0]) {
		throw new Error("Invalid decimal part of exponent");
	}

	// Calculate the value to display
	const valueDisplay =
		parseFloat(exponentParts[0]) *
		10 ** (Number(exponentParts[1]) - (unitIndex - 8) * 3);

	// Return the formatted string
	return `${valueDisplay.toFixed(precision)}${units[unitIndex]}`;
}

const PoolChartTooltip: React.FunctionComponent<TooltipProps<any, any>> = ({
	active,
	payload,
}) => {
	const { formatDate } = useIntl();

	if (active && payload && payload[0] && payload[0].payload) {
		const { date, shares, hashes, transactions } = payload[0].payload as {
			date: Date;
			shares: number;
			hashes: number;
			transactions: number;
		};

		return (
			<div className="bg-zinc-800 px-3 py-1.5 rounded text-sm">
				<span className="font-medium">
					{formatDate(date, {
						day: "numeric",
						month: "long",
						year: "numeric",
						hour: "2-digit",
						minute: "2-digit",
					})}
				</span>

				<div className="flex flex-col gap-1 mt-1">
					<div className="flex items-center">
						<div className="w-0.5 h-4 bg-zinc-100/10 rounded-full mr-2" />

						<span className="w-20">Hashrate:</span>

						<span className="font-medium">
							{printHashrate(hashes / 3_600)}H/s
						</span>
					</div>

					<div className="flex items-center">
						<div className="w-0.5 h-4 bg-zinc-100/20 rounded-full mr-2" />

						<span className="w-20">Blocks:</span>

						<span className="font-medium">{transactions}</span>
					</div>
				</div>
			</div>
		);
	}

	return null;
};

const PoolChart: React.FunctionComponent<{
	data: Array<{
		date: string;
		shares: number;
		hashes: number;
		transactions: number;
	}>;
}> = ({ data }) => {
	const { formatDate } = useIntl();

	return (
		<ResponsiveContainer width="100%" height={100}>
			<AreaChart data={data.slice(0, 10)}>
				<XAxis
					reversed
					dataKey="date"
					tickFormatter={(value: Date) =>
						formatDate(value, {
							// day: "numeric",
							// month: "short",
							hour: "numeric",
						})
					}
					className="text-sm text-zinc-300"
					padding={{ left: 5, right: 5 }}
					tick={{
						fill: "currentColor",
					}}
					tickLine={{
						className: "text-zinc-700",
						stroke: "currentColor",
					}}
					axisLine={{
						className: "text-zinc-700",
						stroke: "currentColor",
					}}
					interval="preserveStartEnd"
				/>

				{/* <YAxis
					className="text-sm text-zinc-300"
					padding={{ top: 5, bottom: 5 }}
					tick={{
						fill: "currentColor",
					}}
					tickLine={{
						className: "text-zinc-700",
						stroke: "currentColor",
					}}
					axisLine={{
						className: "text-zinc-700",
						stroke: "currentColor",
					}}
				/> */}

				<Tooltip
					content={PoolChartTooltip}
					cursor={{
						className: "text-zinc-700",
						stroke: "currentColor",
					}}
				/>

				<Area
					type="monotone"
					dataKey="shares"
					stroke="none"
					fill="currentColor"
					className="text-zinc-100/10"
					dot={false}
					activeDot={{
						className: "text-zinc-500",
						stroke: "currentColor",
						fill: "currentColor",
						r: 2,
					}}
					stackId="1"
				/>

				<Area
					type="monotone"
					dataKey="transactions"
					stroke="none"
					fill="currentColor"
					className="text-zinc-100/5"
					dot={false}
					activeDot={{
						className: "text-zinc-500",
						stroke: "currentColor",
						fill: "currentColor",
						r: 2,
					}}
					stackId="1"
				/>
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default PoolChart;
