import { useRef, useState } from "react";
import {
	FloatingArrow,
	arrow,
	offset,
	safePolygon,
	useFloating,
	useHover,
	useInteractions,
} from "@floating-ui/react";
import { animated, useTransition } from "@react-spring/web";

const Tooltip: React.FunctionComponent<{
	children(bindings: {}): React.ReactNode;
	content: React.ReactNode;
}> = ({ children, content }) => {
	const arrowRef = useRef<SVGSVGElement | null>(null);
	const [open, onOpenChange] = useState(false);

	const floating = useFloating({
		open,
		onOpenChange,
		middleware: [
			arrow({
				element: arrowRef,
			}),
			offset(15),
		],
	});

	const hover = useHover(floating.context, {
		handleClose: safePolygon({
			blockPointerEvents: false,
		}),
	});

	const interactions = useInteractions([hover]);

	const transitions = useTransition(open, {
		from: { opacity: 0, y: -5 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, y: -5 },
		config: { tension: 400 },
	});

	return (
		<div className="relative">
			{children({
				ref: floating.refs.setReference,
				...interactions.getReferenceProps(),
			})}

			{transitions(
				(style, item) =>
					item && (
						<animated.div
							ref={floating.refs.setFloating}
							style={{ ...floating.floatingStyles, ...style }}
							className="bg-zinc-800 px-2.5 py-1.5 rounded pointer-events-none max-w-96 min-w-64 text-sm text-center z-50 shadow"
							{...interactions.getFloatingProps()}
						>
							<FloatingArrow
								ref={arrowRef}
								context={floating.context}
								className="fill-zinc-800"
							/>

							{content}
						</animated.div>
					),
			)}
		</div>
	);
};

export default Tooltip;
